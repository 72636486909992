<template>
  <v-container class="page-body">
    <v-row>
        <v-col>
            <h2>Publications</h2>
            <div class="subsection">
                <div v-for="item in list" :key="item.id">
                    <div class="card-item">
                        <div class="card-published">
                            <span class="card-journal">{{ item.journal }}</span> 
                            <span class="card-date">| {{ item.date }}</span>
                        </div>
                        <h3 class="card-title"><a :href=item.link target="_blank">{{ item.title }}</a></h3>
                        <div class="card-author">{{ item.authorlist }}</div>
                        <p class="card-abstract">{{ item.abstract }}</p>
                        <a class="card-doi" :href=item.link target="_blank">{{ item.doi }}</a>
                        <button v-if="item.minisite_link !=''" :href=item.minisite_link  target="_blank">{{ item.minisite_label }}</button>
                    </div>
                </div>

            </div>

        </v-col>
    </v-row>
     
    </v-container>
</template>

<script>
import * as d3 from "d3";
const csv = 'publications.csv'


export default {
  name: 'TeamPage',
  data () {
        return {
            list: []
        }
      },
      mounted(){
            this.load()
    },
    methods: {
        load(){ 
            Promise
            .all([d3.csv(csv)])
            .then((data) => {
               this.list = data[0]
               console.log(this.list)
            });
        }
    }

};
</script>
<style scoped>



.card-item {
    margin:0px 0px 54px 0px;
}
.card-published{
    margin:8px 0px !important;
}
.card-journal{
    font-weight:900;
    font-size:1em;
    color:#666666;

}
.card-date{
    font-weight:500;
    font-size:1em;
    color:#666666;
}
.card-title{
    margin:8px 0px !important;
}
.card-title a{
    color:black !important;
}
.card-author{
    margin:8px 0px !important;
    color:#666666;
    font-weight: 400;
}
p.card-abstract{
    margin:8px 0px !important;
    padding:0px !important;
}
button{
    margin:0px 16px !important;
}

</style>