<template>
    <v-app-bar
      app
      color="secondary"
      elevation="0"
    >       

      <v-tabs
            background-color="secondary"
            active-class="active_tab"
            v-model="active_tab"
            align-with-title
          >
      
        <v-tab :ripple="false" id="home-tab" key="home" to="/">
            <v-img
              alt="DepMap Initiative Logo"
              class="shrink mr-2 logo-image"
              src="images/logos/depmap-logo.svg"
              transition="scale-transition"
            />
        </v-tab>
        <v-spacer></v-spacer>

 
          <v-tab :ripple="false" key="our-approach" to="/our-approach">Approach</v-tab>
          <v-menu 
          dark
          offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                :ripple="false"
                v-bind="attrs"
                v-on="on"
                class="v-tab"
                id="data-tab"
              >
              Data <span class="material-icons">keyboard_arrow_down</span>
            </div>
            </template>
            <v-list>
              <v-list-item class="v-tab" href="https://depmap.org/portal/" target="_blank">DepMap Public Data Portal</v-list-item>
              <v-list-item  class="v-tab" href="https://dmc.depmap.org/portal/" target="_blank">DMC Access</v-list-item>
            </v-list>
          </v-menu>
          
          <v-tab :ripple="false" key="depmap-consortium" to="/depmap-consortium">Consortium</v-tab>
          <v-tab :ripple="false" key="publications" to="/publications">Publications</v-tab>
          <v-tab :ripple="false" key="our-team" to="/our-team">Team</v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>


  </v-app-bar>
</template>

<script>


export default {
  name: "NavigationBar",
  data () {
      return {
        active_tab: "home"
       }
    },
    methods: {
    }
};
</script>


<style scoped>
.v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab {
    margin-left: 0px;
}
  .v-tab{
    color: white!important;
    text-transform: none;
    font-weight:500;
  }
.v-list{
  background-color:#0d1339;
}

.logo-image{
  max-width:200px !important;
}
.active_tab{
  font-weight:900;
  color:#5e8eff !important;
}

  a:hover, #data-tab:hover, .active_tab:hover{
    color: white !important;
    background-color:#5e8eff !important;
  }
  a {
    color: white !important;
  }

  #home-tab:hover{
    background-color:#0d1339 !important;
  }
  .v-tab::before {
  display: none;
}
.material-icons {
  font-size: 1em !important;
}
.v-list> .v-tab{
  text-align:left;
  justify-content: left;
}

</style>