import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/pages/HomePage';
import ApproachPage from '@/pages/ApproachPage';
import PublicationsPage from '@/pages/PublicationsPage';
import ConsortiumPage from '@/pages/ConsortiumPage';
import TeamPage from '@/pages/TeamPage';

Vue.use(VueRouter);

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/our-approach',
            name: 'ApproachPage',
            component: ApproachPage
        },
        {
            path: '/depmap-consortium',
            name: 'ConsortiumPage',
            component: ConsortiumPage
        },
        {
            path: '/our-team',
            name: 'TeamPage',
            component: TeamPage
        },
        {
            path: '/publications',
            name: 'PublicationsPage',
            component: PublicationsPage
        }
    ]
});



export default router

