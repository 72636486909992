<template>
  <v-container class="page-body">
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h2>Our approach</h2>
        <p class="caption-lg">Our goal is to map the landscape of cancer vulnerabilities through a variety of approaches.</p>
      </v-col>
    </v-row>
    <v-row class="subsection">
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-img class="thumbnail" src="images/approach/approach-01.png"></v-img>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h3>Capture the genetic and molecular diversity of human cancer</h3>
        <p>To represent the diversity of human cancer, DepMap builds on the original Cancer Cell Line Encyclopedia (CCLE) project, which characterized 1000 cell line models. To date, more than 2000 models have been collected. 
        <br><br>
        Modeling human cancer will require the use of a variety of models. In the next phase of DepMap, we will incorporate non-traditional models to more comprehensively capture cancer-specific vulnerabilities across diverse cancer types.
        <br><br>
        DepMap is committed to representing all tumor types including rare cancer, pediatric cancer and cancer models in underrepresented populations. Please reach out to us at <a href="mailto:depmap@broadinstitute.org">DepMap@Broadinstitute.org</a> if you would like to contribute models and help expand DepMap. 
        </p>
        <div class="button-group">
          <a href="https://www.broadinstitute.org/news/md-anderson-and-broad-institute-launch-new-translational-research-platform-focused-rare-cancers" target="_blank" class="button">Rare Cancer Initiative</a>
          <a href="https://www.broadinstitute.org/news/broad-dana-farber-st-jude-join-together-drive-progress-against-pediatric-cancer" target="_blank" class="button">Pediatric Cancer Initiative</a>
        </div>
      </v-col>
    </v-row>
    <v-row class="subsection">
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-img  class="thumbnail"  src="images/approach/approach-02.png">
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h3>Identify genetic and molecular alterations in cancer cells</h3>
        <p>A variety of genomic and molecular alterations may occur in cancer cells as they grow and metastasize, leading to therapeutic vulnerabilities.
        <br><br>
        To capture these alterations, we systematically profiled a panel of 1000 cell lines as part of the CCLE project. 
        <br><br>
        In the next phase of DepMap, we will be expanding these and creating key datasets to more comprehensively capture genome alterations. In addition, we will be building new omics pipelines, and creating new relevant features to support the discovery of new targets and biomarkers.
        </p>
        <div class="button-group">
          <a href="https://sites.broadinstitute.org/ccle/" target="_blank" class="button">Cancer Cell Line Encyclopedia</a>
        </div>
      </v-col>
    </v-row>
    <v-row class="subsection">
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-img class="thumbnail" src="images/approach/approach-03.png">
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h3>Enumerate all genes that are required for cell growth and drug sensitivities</h3>
        <p>In DepMap, we use large-scale functional genomics profiling to identify the genes required for cell growth. To date, we have performed genome-wide RNAi and CRISPR loss-of-function screens in more than 1000 cancer cell lines.
        <br><br>
        In parallel, we are employing a multiplexed approach (PRISM) to profile hundreds of cellular models for drug sensitivities. After profiling the  drug repurposing library, we are continuing to profile new compounds of interest to create the largest oncology reference dataset to date. 
        <br><br>
        At the core of our activities, we are building new analytical tools to better capture these genetic dependencies and drug sensitivities. Methods such as DEMETER, DEMETER2, CERES and CHRONOS have helped us better model cancer dependencies and drug sensitivities.
          <br><br>
          We are expanding to include new screening modalities, new perturbations and non-traditional models to more comprehensively capture gene dependencies and drug sensitivities.
        </p>
        <div class="button-group">
          <a href="https://www.theprismlab.org/" target="_blank" class="button">PRISM</a>
          <a href="https://www.broadinstitute.org/drug-repurposing-hub" target="_blank" class="button">Drug Repurposing Library</a>
          <a href="https://www.broadinstitute.org/genetic-perturbation-platform" target="_blank" class="button">Genetic Perturbation Platform</a>
        </div>
      </v-col>
    </v-row>
    <v-row class="subsection">
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-img class="thumbnail" src="images/approach/approach-04.png">
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h3>Create a map of cancer vulnerabilities</h3>
        <p>We are building connections across our datasets to identify relationships between genetic dependencies, drug sensitivities and cellular features. By linking gene dependencies and drug sensitivities to cancer genetic and molecular features, we can uncover new cancer vulnerabilities, identify biomarkers of response and gain insights into mechanisms of action. 
        <br><br>
        We are continuing to develop more tools to improve and interpret predictive models, new ways to integrate the data and create better apps to support target and biomarker discovery. 
          </p>
        <div class="button-group">
          <a href="http://cancerdatascience.org/" target="_blank" class="button">Cancer Data Science</a>
          <a href="https://target-discovery.depmap.org/" target="_blank" class="button">Target Discovery and Advancement team</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'ApproachPage'
};
</script>
<style scoped>

.button-group{
  text-align: right;
}
.thumbnail{
  margin:16px 0px 0px 0px;
  max-width: 400px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 10%);
}



</style>  