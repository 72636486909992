import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
        values: {
            info: 'mdi-dropbox',
        }
      },
      theme: {
        themes: {
          light: {
            primary: '#5e8eff',
            secondary: '#0d1339',
            light: "#cacaca",
            accent: '#8c9eff',
            error: '#e41e2d'
          },
        },
      }
});
