<template>
  <v-container class="page-body">
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8">
        <h2>DepMap Consortium</h2>
        <p>Since its founding in 2018, the <a href="https://www.broadinstitute.org/news/broad-institute-launches-academic-industrial-consortium-cancer-dependency-studies" target="_blank">DepMap Consortium (DMC)</a> has brought together the world-class expertise of the Broad Institute with the drug discovery and translational expertise of industry partners to work on the joint mission to create resources to accelerate the pace of precision cancer medicine. 
        <br><br>
        DMC is a pre-competitive partnership to comprehensively map the landscape of cancer vulnerabilities across all cancers through the creation of large-scale systematic datasets and innovative analytical tools across large-panels of diverse human cancer models. 
        <br><br>
        Consortium partners work closely with the DepMap and other cancer program teams to bring value to drug discovery initiatives.
        <br><br>
        To learn more about the DepMap Consortium, see <a href="pdfs/DepMap_ConsortiumOP.pdf">this overview</a> and contact the DepMap Alliance Management team at <a href="mailto:dmcalliance@broadinstitute.org">dmcalliance@broadinstitute.org</a>.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <div class="subsection">
          <h3>DepMap Consortium Partners</h3>
          <v-row>
            <div v-for="(item, index) in partners" :key="index" class="logo-thumbnail d-flex child-flex">
              <img
                :src="`images/contributor-logos/${item}`"
                :lazy-src="`images/contributor-logos/${item}`"
              />
            </div>
          </v-row>
        </div>
       
        <div class="subsection">
          <h3>Pediatric Cancer Dependencies Accelerator</h3>
          <v-row>
            <div v-for="(item, index) in pediatric" :key="index" class="logo-thumbnail d-flex child-flex">
              <img
                :src="`images/contributor-logos/${item}`"
                :lazy-src="`images/contributor-logos/${item}`"
              />
            </div>
          </v-row>
        </div>
       
      </v-col>
    </v-row>

  </v-container>
</template>

<script>


export default {
  name: 'ConsortiumPage',
  data () {
        return {
          partners: ["abbVie.png","amgen.png", "astrazeneca.png", "bayer.png", "bms.png", "bi.png", "calico.png", "exelixis.png", "genentech.png","gsk.png", "ideaya.png", "incyte.png", "janssen.png", "loxo_lilly.png","odyssey.png", "pfizer.png", "repare.png", "servier.png"],
          pediatric: ["sjcrh.png", "dfci.png"]
        }
      }
};
</script>
<style>
.logo-thumbnail{
  width:170px;
  background-color:none;
  border: .25px solid lightgray;
  padding:16px !important;
  margin:10px;

}

</style>