<template>
    <v-container class="page-body">
    <v-row>
      <v-col cols="12">
        <h2>Our team</h2>
 
        <v-row class="subsection">
        <v-card v-for="(item, i) in team"
            :key="i"
            height="auto"
            width="100%"
            class="team-card"
          >
            <v-img
              aspect-ratio="1"
              :src='item.src'
            ></v-img>
            <v-card-title>
              {{ item.name }} 
            </v-card-title>
            <v-card-text>
              {{ item.title }}
            </v-card-text>
          </v-card>
      </v-row>
      <v-row class="subsection">
        <h3>Scientific Advisors</h3>
        <v-card v-for="(item, i) in advisors"
            :key="i"
            height="auto"
            width="100%"
            class="team-card"
          >
            <v-img
              aspect-ratio="1"
              :src='item.src'
            ></v-img>
            <v-card-title>
              {{ item.name }} 
            </v-card-title>
            <v-card-text>
              {{ item.title }}
            </v-card-text>
          </v-card>




      </v-row>
           </v-col>
          </v-row>
    </v-container>
</template>

<script>


export default {
  name: 'TeamPage',
  data () {
        return {
          team: [
                {name:"Francisca Vazquez", title: "Director, DepMap", src:'images/team/Paquita-Vazquez.jpg'},
                {name:"Samantha Stokes", title: "Program Manager, DepMap", src:'images/team/Samantha-Stokes.jpg'},
                {name:"Kelly Sullivan", title: "DMC Alliance Manager", src:'images/team/Kelly-Sullivan.jpg'},
                {name:"Kirsty Wienand", title: "Scientific Lead, DepMap", src:'images/team/Kirsty-Wienand.jpg'},
                {name:"Katie Campell", title: "Director, Cancer Data Science", src:'images/team/Katie-Campbell.jpg'},
                {name:"Philip Montgomery", title: "Associate Director, Software", src:'images/team/Philip-Montgomery.jpg'},
                {name:"Josh Dempster", title: "Group Leader, Computational Biology", src:'images/team/Josh-Dempster.jpg'},
                {name:"Jen Roth", title: "Director, PRISM", src:'images/team/Jen-Roth.jpg'}
          ],
          advisors: [
            {name: "William Sellers", src:"images/team/William-Sellers.png"},
            {name: "William Hahn", src:"images/team/William-Hahn.jpg"},
            {name: "Todd Golub", src:"images/team/Todd-Golub.jpg"},
            {name: "Kimberly Stegmaier", src:"images/team/Kimberly_Stegmaier.jpg"},
            {name: "Charles Roberts", src:"images/team/Charles-Roberts.jpg"},
            {name: "Andrew Aguirre", src:"images/team/Andrew-Aguirre.jpg"},
            {name: "David Root", src:"images/team/David-Root.jpg"}
         ]
        }
      },
};
</script>
<style scoped>
.team-card{
  width:calc(25% - 20px)!important;
  min-width:200px;
  max-width:300px;
  margin:10px;
}
.team-card > .v-card__title{
    font-size:1em;
    font-weight:500;
    line-height:1.2em;
    word-break:normal;   
    padding-bottom:4px !important;
}
.team-card > .v-card__text{
    font-size:1em;
    font-weight:500;
    line-height:1.2em;
    word-break:normal;   
    margin:0px !important;
}
button{
  margin-left:24px !important;
}


@media (max-width: 550px) {
  .team-card{
    width:45%!important;
    margin: 8px;
  }
}

</style>