<template>
<footer>
        <v-row  class="d-flex justify-space-between">
            <v-col cols="12" lg="4" md="4" sm="12">
            <v-img
              alt="Broad Logo"
              class="shrink m-2 logo-image"
              src="images/logos/broad-logo.svg"
              transition="scale-transition"
            />
            <small>Copyright © 2021 Broad Institute. All rights reserved. <a href="https://depmap.org/portal/privacy/" target="_blank">Privacy</a> | <a href="https://depmap.org/portal/terms/" target="_blank">Terms & Conditions</a></small>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
                <div class="footer-header">Connect</div>
                <ul>
                    <li><a href="https://twitter.com/CancerDepMap" target="_blank">@CancerDepMap</a></li>
                    <li><a href="https://forum.depmap.org/"  target="_blank">Feedback</a></li>
                    <a target="_blank" href="https://depmap.org/hiring/">Join us!</a>
                </ul>
               
                
            </v-col>
        </v-row>

</footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
.v-row{
    justify-content: space-between;
}
footer {
    background-color: #0d1339;
    color: white !important;
    vertical-align:middle;
    padding:24px;
}
.logo-image{
    width:200px;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.footer-header{
    text-transform: uppercase;
}
@media screen and (min-width: 200px) and (max-width: 768px) {


   footer {
        padding:1.2rem 1rem 1rem 1rem;
        
    }
    #footer-logo{
        width:180px;
        margin:0rem 1rem 3rem 0rem;
    }

}
</style>