<template>
    <div>
      <div class="banner-feature">
        <v-container>
          <v-col cols="12" sm="12" md="9" lg="8">
            <h1>Towards mapping the landscape of cancer vulnerabilities across all tumors</h1>
            <a href="https://depmap.org/portal/" style="text-decoration: none; border-bottom: 0px;">
                <button class="lg">Explore the DepMap public data <span class="material-icons">east</span></button>
            </a>
            <br>
            <a target="_blank" href="https://dmc.depmap.org/portal/">DMC Access</a>
          </v-col>
        </v-container>
      </div>
      <div class="banner-section">
        <v-container>
          <v-col cols="12"  sm="12" md="10" lg="8" offset="0" offset-sm="0" offset-md="1" offset-lg="2">
            <span class="caption-lg">
              To accelerate precision cancer medicine, we construct systematic key datasets, analytical and visualization tools, using broad panels of cancer models that represent the diversity of human cancers.
            </span>
          </v-col>
        </v-container>
      </div>

      <v-container>
        <section>
          <h2>Our mission</h2>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <VImageCard title="Represent all human cancer genetic and molecular diversity" img="images/mission/mission-01.png"></VImageCard>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <VImageCard title="Identify and understand the landscape of cancer dependencies" img="images/mission/mission-02.png"></VImageCard>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <VImageCard title="Create a resource for drug target and biomarker discovery" img="images/mission/mission-03.png"></VImageCard>
            </v-col>
          </v-row>
        </section>

        <section>
          <v-row>
            <router-link to="/our-approach" class="iconned-button">
              <img class="icon" src="images/icon-approach.png"/>
              <span>Learn more about our approach <span class="material-icons">east</span></span>
            </router-link>
            <a target="_blank" href="https://depmap.org/portal/" class="iconned-button">
              <img class="icon" src="images/icon-explore.png"/>
              <span>Explore the depmap data <span class="material-icons">east</span></span>
            </a>
          </v-row>
        </section>

        
      </v-container>    
    </div>
</template>
<script>

import VImageCard from '../components/VImageCard';
export default {
  name: 'Homepage',
  components: {
    VImageCard
  },
  methods: {
    clickApproach(e){
      console.log(e)
    }
  }
};
</script>
<style scoped>


.banner-feature{
  background-image: url("../../public/images/banner.png");
  width:101%;
  color:white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding:60px 0px 72px 0px;
  margin:0;
}


.banner-feature>* h1{
  color:white;
  font-weight:900;
  font-size:3.6em;
}


@media (max-width: 550px) {
  .banner-feature > .container >* {
    max-width:100%;
    margin-left:0px;
  }
}

</style>