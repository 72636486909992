<template>
  <v-app>
    <NavigationBar></NavigationBar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import Footer from './components/Footer.vue';
export default {
  name: 'App',
  components: {
    NavigationBar,
    Footer
  }
};
</script>
<style>


</style>